import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: ':id',
    loadChildren: () => import('./modules/events/pages/event/event.module').then( m => m.EventPageModule)
  },
  /*{
    path: 'privacy',
    redirectTo: 'help/legal',
    pathMatch: 'full'
  },
  {
    path: 'contact',
    redirectTo: 'help/contact',
    pathMatch: 'full'
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then( m => m.HelpPageModule)
  },*/
 /* {
    path: 'events',
    loadChildren: () => import('./modules/events/pages/events/events.module').then( m => m.EventsPageModule)
  },*/
 /* {
    path: 'games',
    loadChildren: () => import('./games/games.module').then( m => m.GamesPageModule)
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then( m => m.ShopPageModule)
  },
  {
    path: 'stats',
    loadChildren: () => import('./stats/stats.module').then( m => m.StatsPageModule)
  },*/
  // {
  //   path: 'shop',
  //   loadChildren: () => import('./shop/shop.module').then( m => m.ShopPageModule)
  // },
 /* {
    path: 'turbo84',
    loadChildren: () => import('./turbo84/turbo84.module').then( m => m.Turbo84PageModule)
  },*/
  {
    path: 'event/:id',
    loadChildren: () => import('./modules/events/pages/event/event.module').then( m => m.EventPageModule)
  },
 /* {
    path: 'proofofspeed',
    loadChildren: () => import('./proofofspeed/proofofspeed.module').then( m => m.ProofofspeedPageModule)
  },
  {
    path: 'twitch-score',
    loadChildren: () => import('./twitch-score/twitch-score.module').then( m => m.TwitchScorePageModule)
  },
  {
    path: 'twitch-pbstream',
    loadChildren: () => import('./twitch-pbstream/twitch-pbstream.module').then( m => m.TwitchPbstreamPageModule)
  },
  {
    path: 'twitch-leaderboard',
    loadChildren: () => import('./twitch-leaderboard/twitch-leaderboard.module').then( m => m.TwitchLeaderboardPageModule)
  },*/
  {
    path: 'prize/:id',
    loadChildren: () => import('./prize/prize.module').then( m => m.PrizePageModule)
  },
  { path: '**', redirectTo: 'event' },





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

