import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-toolbar-button',
  templateUrl: './toolbar-button.component.html',
  styleUrls: ['./toolbar-button.component.scss'],
})
export class ToolbarButtonComponent  {

  @Input() 
  public route: string = 'games'

  @Input() 
  public label: string = 'Games'
  
  constructor(public router: Router) {}
}
