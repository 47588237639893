import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarButtonComponent } from './toolbar-button/toolbar-button.component'
import { IonicModule } from '@ionic/angular'
import { AppRoutingModule } from '../app-routing.module'

@NgModule({
  declarations: [ToolbarButtonComponent],
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    AppRoutingModule,
  ],
  exports: [
    ToolbarButtonComponent
  ]
})
export class ThndrComponentsModule { }
