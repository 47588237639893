// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBsbQUBAgOCQcE33Rw-Z9nNXxMVMdLJe_E",
    authDomain: "thndr-games.firebaseapp.com",
    databaseURL: "https://thndr-games.firebaseio.com",
    projectId: "thndr-games",
    storageBucket: "thndr-games.appspot.com",
    messagingSenderId: "987193886912",
    appId: "1:987193886912:web:cb8d97b1850034a14d4525",
    measurementId: "G-5BPFN7LTBP"
  },
  eventBaseURL: "https://event.thndr.io/v0/",
  apiBaseURL: "https://api.thndr.io/v0/"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
