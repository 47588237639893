import { Component, HostListener, OnInit } from '@angular/core'
import { Platform } from '@ionic/angular'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'
import { Plugins } from '@capacitor/core';
import { ScreensizeService } from './services/screensize.service';
import { Router } from '@angular/router';
const { Browser } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public isDesktop = false
  public isProofOfSpeed = false
  public selectedIndex = 0
  public appPages = [
    {
      title: 'Games',
      url: '/games',
      icon: 'game-controller'
    },
    /*{
      title: 'Earn',
      url: '/events',
      icon: 'trophy'
    },*/
    // {
    //   title: 'Stats',
    //   url: '/stats',
    //   icon: 'bar-chart'
    // },
   /* {
      title: 'Shop',
      url: '/shop',
      icon: 'cart'
    },*/
    
    {
      title: 'Help',
      url: '/help',
      icon: 'help-circle'
    }
  ];

  constructor(
    private router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private screensizeService: ScreensizeService) {
    this.initializeApp()
    this.screensizeService.isDesktopView().subscribe(isDesktop => {
      this.isDesktop = ScreensizeService.switchToDesktop(this.isDesktop, isDesktop)
    });
  }


  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault()
      this.splashScreen.hide()
      this.screensizeService.onResize(this.platform.width())
    });
  }

  ngOnInit() {
    this.isProofOfSpeed = this.router.url === '/proofofspeed'

    this.router.events.subscribe((val) => {
      this.isProofOfSpeed = this.router.url === '/proofofspeed'  
    })

    const path = window.location.pathname;
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  async openURL(url) {
    await Browser.open({ 'url': url });
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.screensizeService.onResize(event.target.innerWidth);
  }
}
