export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBsbQUBAgOCQcE33Rw-Z9nNXxMVMdLJe_E",
    authDomain: "thndr-games.firebaseapp.com",
    databaseURL: "https://thndr-games.firebaseio.com",
    projectId: "thndr-games",
    storageBucket: "thndr-games.appspot.com",
    messagingSenderId: "987193886912",
    appId: "1:987193886912:web:cb8d97b1850034a14d4525",
    measurementId: "G-5BPFN7LTBP"
  },
  eventBaseURL: "https://event.thndr.io/v0/",
  apiBaseURL: "https://api.thndr.io/v0/"
}